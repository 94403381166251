import React, { useState, useEffect } from 'react';
import Layout from '../components/Layout';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import Modal from 'react-modal';
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { destroyCookie } from 'nookies';
const API_URL = process.env.GATSBY_API_URL || 'http://localhost:9000';
const STRIPE_PK =
  process.env.GATSBY_STRIPE_PK || 'pk_test_FjZRqF2ciFEsJzjow8rRdqMW';
const STRIPE_URL =
  process.env.GATSBY_STRIPE_URL || 'http://localhost:9000/stripe/intent';

const StripeCheckout = ({ paymentIntent, onPaymentSuccess, name }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [checkoutError, setCheckoutError] = useState();
  const [checkoutSuccess, setCheckoutSuccess] = useState();
  const [pending, setPending] = useState(false);

  const handlePayment = async (e) => {
    e.preventDefault();
    setPending(true);
    try {
      const {
        error,
        paymentIntent: { status },
      } = await stripe.confirmCardPayment(paymentIntent.client_secret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: name,
          },
        },
      });

      if (error) throw new Error(error.message);

      if (status === 'succeeded') {
        setCheckoutSuccess(true);
        onPaymentSuccess();
        destroyCookie(null, 'paymentIntentId');
      } else {
        alert(
          'Your payment could not be processed. Please make sure your credit card info is correct and try again.'
        );
        setPending(false);
      }
    } catch (err) {
      alert(
        'Your payment could not be processed. Please make sure your credit card info is correct and try again.'
      );
      setPending(false);
      setCheckoutError('Please try again');
    }
  };

  return (
    <div style={{ width: '100%' }}>
      <CardElement
        className="StripeElement"
        options={{
          style: {
            base: {
              lineHeight: '100px',
              fontWeight: 500,
              fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
              fontSize: '24px',
              '::placeholder': {
                color: '#CFD7E0',
              },
            },
          },
        }}
      />
      <button
        type="button"
        className="btn"
        onClick={handlePayment}
        disabled={!stripe || pending}
        style={{
          minHeight: 40,
          backgroundColor: '#111d27',
          color: '#fff',
          fontSize: 20,
          borderRadius: 8,
          maxWidth: 750,
        }}
      >
        {pending ? 'Completing Payment...' : 'Pay Now'}
      </button>
      {checkoutError && (
        <div style={{ marginTop: 60 }}>
          <span style={{ color: 'red' }}>{checkoutError}</span>
        </div>
      )}
    </div>
  );
};

const MyAccount = () => {
  useEffect(() => {
    setWidth(window.innerWidth);
    window.addEventListener('resize', () => {
      setWidth(window.innerWidth);
    });
    return () => {
      window.removeEventListener('resize', () => {});
    };
  }, []);
  const stripePromise = loadStripe(STRIPE_PK);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [sent, setSent] = useState(false);
  const [error, setError] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [width, setWidth] = useState(200);
  const [paymentIntent, setPaymentIntent] = useState('');
  const [userData, setUserData] = useState();
  const [course, setCourse] = useState();
  const [jwt, setJwt] = useState('');
  const [certificateInfo, setCertificateInfo] = useState({
    fullName: '',
    email: userData?.user.email,
    phone: '',
    licenseType: '',
    licenseState: '',
    licenseNumber: '',
    licenseExpiration: '',
    address: '',
    city: '',
    state: '',
    zip: '',
  });

  useEffect(() => {
    (async () => {
      let params = new URLSearchParams(window.location.search);
      let jwt = params.get('id');
      if (!jwt) {
        jwt = localStorage.getItem('token');
      }
      setJwt(jwt);
      const jwtData = jwt_decode(jwt);
      localStorage.setItem('token', jwt);
      const userId = jwtData.userId;
      const headers = {
        headers: { 'x-access-token': jwt },
      };
      try {
        const userData = await axios.get(`${API_URL}/user/${userId}`, headers);
        setUserData(userData.data);
      } catch (err) {
        localStorage.clear();
        window.location.href = '/signin';
      }
    })();
  }, []);

  const isBrowser = typeof window !== 'undefined';
  if (!isBrowser) return null;

  if (!userData) return null;

  const signOut = () => {
    localStorage.clear();
    window.location.href = '/';
  };

  const unpaidCourses = () => {
    if (!userData?.unpaidCourses) return null;
    const Unpaid = userData.unpaidCourses.map((course, index) => (
      <Course
        course={course}
        userData={userData}
        stripePromise={stripePromise}
      />
    ));

    return Unpaid;
  };

  const certificates = () => {
    if (!userData?.certificates) return null;
    const Certificates = userData.certificates.map((cert, index) => (
      <Certificate cert={cert} />
    ));
    return Certificates;
  };

  const takeTestLink =
    userData?.unpaidCourses.length > 0 ? '/FL/Engineering/FLENG51' : '/';
  const coursesText =
    userData?.unpaidCourses.length > 0 ? 'Pending Payment' : 'My Courses';

  return (
    <Layout fullMenu>
      <article id="main">
        <section className="wrapper style5">
          <h2 style={{ textAlign: 'center' }}>My Account</h2>
          <div className="inner">
            <h4 style={{ color: 'black' }}>Personal Info</h4>
            <p style={{ color: 'black' }}>Email: {userData?.user.email}</p>
            {/* <button>Set Password</button> */}
            <hr style={{ margin: '20px 0px' }} />
            <>
              <h4 style={{ color: 'black', marginBottom: 0 }}>{coursesText}</h4>
              <a
                style={{ cursor: 'pointer' }}
                onClick={() => (window.location.href = takeTestLink)}
              >
                {userData?.unpaidCourses.length > 0
                  ? 'Take Another Test'
                  : 'Find a Course'}
              </a>
            </>
            {unpaidCourses()}
            <hr style={{ margin: '20px 0px' }} />
            {userData?.certificates.length > 0 && (
              <h4 style={{ color: 'black' }}>My Certificates</h4>
            )}
            {certificates()}
          </div>
          <div className="inner" style={{ marginTop: 0 }}>
            <h4>Sign Out</h4>
            <button onClick={signOut}>Sign Out</button>
          </div>
        </section>
      </article>
    </Layout>
  );
};

const Course = ({ course, userData, stripePromise }) => {
  const [showCode, setShowCode] = useState(false);
  const [code, setCode] = useState('');
  const [couponResponse, setCouponResponse] = useState('');
  const [amountDue, setAmountDue] = useState(course.amountDue);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [width, setWidth] = useState(200);
  const [paymentIntent, setPaymentIntent] = useState('');
  const [jwt, setJwt] = useState('');
  const [certificateInfo, setCertificateInfo] = useState({
    fullName: '',
    email: userData?.user.email,
    phone: '',
    licenseType: '',
    licenseState: '',
    licenseNumber: '',
    licenseExpiration: '',
    address: '',
    city: '',
    state: '',
    zip: '',
  });

  const applyCode = async () => {
    try {
      const result = await axios.get(`${API_URL}/discounts/${code}`);
      const coupon = result.data;
      if (coupon.amount.includes('%')) {
        const percent = parseFloat(coupon.amount.split('%')[0] / 100);
        const due = parseFloat(course.amountDue - percent * course.amountDue);
        const finalAmount = Math.max(due, 0.5);
        setAmountDue(finalAmount);
        setCouponResponse({ error: false, message: 'Coupon Applied!' });
        setShowCode(false);
      }
    } catch (err) {
      setCouponResponse({ error: true, message: 'Invalid Code' });
    }
  };

  const surveyPay = async () => {
    openModal();
    const res = await axios.post(STRIPE_URL, {
      currency: 'usd',
      amount: parseFloat(amountDue),
      email: userData.user.email,
      userId: userData.user.id,
      force3dSecure: true,
    });
    setPaymentIntent(res.data);

    console.log(`Show data: ${paymentIntent}`);
  };

  const _renderPayment = () => {
    return (
      <Elements stripe={stripePromise}>
        <StripeCheckout
          paymentIntent={paymentIntent}
          onPaymentSuccess={onPaymentSuccess}
          name={certificateInfo.fullName}
        />
      </Elements>
    );
  };

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const customStyles = {
    content: {
      width: '70%',
      height: '70%',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      color: 'black',
    },
  };

  const onPaymentSuccess = async () => {
    const userId = userData.user.id;
    const email = userData.user.email;
    const titles = userData.unpaidCourses
      .map((course) => course.title)
      .join('::');
    try {
      const certRes = await axios.post(`${API_URL}/certificates`, {
        ...certificateInfo,
        userId,
        titles,
        courseCode: course.code,
        creditHours: course.hoursFulfilled,
      });
      const cert = certRes.data;
      await axios.post(`${API_URL}/payments`, {
        userId,
        stripePaymentIntent: paymentIntent.id,
        courseCode: course.code,
        paymentAmount: course.amountDue,
        couponCode: code,
        amountCharged: amountDue,
      });
      axios.post(`${API_URL}/certificate/pdf`, {
        email,
        certificateId: cert.uuid,
      });
      alert('Your payment was successfull!');
      window.open(
        'https://docs.google.com/forms/d/e/1FAIpQLSeQFcNTNDVAYqzT2LUhzvhUAFJ292sDXb2uHxASwM0g2747XQ/viewform?usp=sf_link',
        '_blank'
      );
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
    closeModal();
  };

  const SelectState = () => {
    return (
      <select
        // style={{ marginTop: 30, marginBottom: 30 }}
        onChange={(e) =>
          setCertificateInfo({
            ...certificateInfo,
            licenseState: e.target.value,
          })
        }
      >
        <option value="">1. Select State</option>
        <option
          selected={certificateInfo.licenseState == 'Alabama'}
          value="Alabama"
        >
          Alabama
        </option>
        <option
          selected={certificateInfo.licenseState == 'Alaska'}
          value="Alaska"
        >
          Alaska
        </option>
        <option
          selected={certificateInfo.licenseState == 'Arizona'}
          value="Arizona"
        >
          Arizona
        </option>
        <option
          selected={certificateInfo.licenseState == 'Arkansas'}
          value="Arkansas"
        >
          Arkansas
        </option>
        <option
          selected={certificateInfo.licenseState == 'California'}
          value="California"
        >
          California
        </option>
        <option
          selected={certificateInfo.licenseState == 'Colorado'}
          value="Colorado"
        >
          Colorado
        </option>
        <option
          selected={certificateInfo.licenseState == 'Connecticut'}
          value="Connecticut"
        >
          Connecticut
        </option>
        <option
          selected={certificateInfo.licenseState == 'Delaware'}
          value="Delaware"
        >
          Delaware
        </option>
        <option
          selected={certificateInfo.licenseState == 'Florida'}
          value="Florida"
        >
          Florida
        </option>
        <option
          selected={certificateInfo.licenseState == 'Georgia'}
          value="Georgia"
        >
          Georgia
        </option>
        <option selected={certificateInfo.licenseState == 'Guam'} value="Guam">
          Guam
        </option>
        <option
          selected={certificateInfo.licenseState == 'Hawaii'}
          value="Hawaii"
        >
          Hawaii
        </option>
        <option
          selected={certificateInfo.licenseState == 'Idaho'}
          value="Idaho"
        >
          Idaho
        </option>
        <option
          selected={certificateInfo.licenseState == 'Illinois'}
          value="Illinois"
        >
          Illinois
        </option>
        <option
          selected={certificateInfo.licenseState == 'Indiana'}
          value="Indiana"
        >
          Indiana
        </option>
        <option selected={certificateInfo.licenseState == 'Iowa'} value="Iowa">
          Iowa
        </option>
        <option
          selected={certificateInfo.licenseState == 'Kansas'}
          value="Kansas"
        >
          Kansas
        </option>
        <option
          selected={certificateInfo.licenseState == 'Kentucky'}
          value="Kentucky"
        >
          Kentucky
        </option>
        <option
          selected={certificateInfo.licenseState == 'Louisiana'}
          value="Louisiana"
        >
          Louisiana
        </option>
        <option
          selected={certificateInfo.licenseState == 'Maine'}
          value="Maine"
        >
          Maine
        </option>
        <option
          selected={certificateInfo.licenseState == 'Maryland'}
          value="Maryland"
        >
          Maryland
        </option>
        <option
          selected={certificateInfo.licenseState == 'Massachusetts'}
          value="Massachusetts"
        >
          Massachusetts
        </option>
        <option
          selected={certificateInfo.licenseState == 'Michigan'}
          value="Michigan"
        >
          Michigan
        </option>
        <option
          selected={certificateInfo.licenseState == 'Minnesota'}
          value="Minnesota"
        >
          Minnesota
        </option>
        <option
          selected={certificateInfo.licenseState == 'Mississippi'}
          value="Mississippi"
        >
          Mississippi
        </option>
        <option
          selected={certificateInfo.licenseState == 'Missouri'}
          value="Missouri"
        >
          Missouri
        </option>
        <option
          selected={certificateInfo.licenseState == 'Montana'}
          value="Montana"
        >
          Montana
        </option>
        <option
          selected={certificateInfo.licenseState == 'Nebraska'}
          value="Nebraska"
        >
          Nebraska
        </option>
        <option
          selected={certificateInfo.licenseState == 'Nevada'}
          value="Nevada"
        >
          Nevada
        </option>
        <option
          selected={certificateInfo.licenseState == 'New Hampshire'}
          value="New Hampshire"
        >
          New Hampshire
        </option>
        <option
          selected={certificateInfo.licenseState == 'New Jersey'}
          value="New Jersey"
        >
          New Jersey
        </option>
        <option
          selected={certificateInfo.licenseState == 'New Mexico'}
          value="New Mexico"
        >
          New Mexico
        </option>
        <option
          selected={certificateInfo.licenseState == 'New York'}
          value="New York"
        >
          New York
        </option>
        <option
          selected={certificateInfo.licenseState == 'North Carolina'}
          value="North Carolina"
        >
          North Carolina
        </option>
        <option
          selected={certificateInfo.licenseState == 'North Dakota'}
          value="North Dakota"
        >
          North Dakota
        </option>
        <option selected={certificateInfo.licenseState == 'Ohio'} value="Ohio">
          Ohio
        </option>
        <option
          selected={certificateInfo.licenseState == 'Oklahoma'}
          value="Oklahoma"
        >
          Oklahoma
        </option>
        <option
          selected={certificateInfo.licenseState == 'Oregon'}
          value="Oregon"
        >
          Oregon
        </option>
        <option
          selected={certificateInfo.licenseState == 'Pennsylvania'}
          value="Pennsylvania"
        >
          Pennsylvania
        </option>
        <option
          selected={certificateInfo.licenseState == 'Puerto Rico'}
          value="Puerto Rico"
        >
          Puerto Rico
        </option>
        <option
          selected={certificateInfo.licenseState == 'Rhode Island'}
          value="Rhode Island"
        >
          Rhode Island
        </option>
        <option
          selected={certificateInfo.licenseState == 'South Carolina'}
          value="South Carolina"
        >
          South Carolina
        </option>
        <option
          selected={certificateInfo.licenseState == 'South Dakota'}
          value="South Dakota"
        >
          South Dakota
        </option>
        <option
          selected={certificateInfo.licenseState == 'Tennessee'}
          value="Tennessee"
        >
          Tennessee
        </option>
        <option
          selected={certificateInfo.licenseState == 'Texas'}
          value="Texas"
        >
          Texas
        </option>
        <option
          selected={certificateInfo.licenseState == 'US Virgin Islands'}
          value="US Virgin Islands"
        >
          US Virgin Islands
        </option>
        <option selected={certificateInfo.licenseState == 'Utah'} value="Utah">
          Utah
        </option>
        <option
          selected={certificateInfo.licenseState == 'Vermont'}
          value="Vermont"
        >
          Vermont
        </option>
        <option
          selected={certificateInfo.licenseState == 'Virginia'}
          value="Virginia"
        >
          Virginia
        </option>
        <option
          selected={certificateInfo.licenseState == 'Washington'}
          value="Washington"
        >
          Washington
        </option>
        <option
          selected={certificateInfo.licenseState == 'Washington DC'}
          value="Washington DC"
        >
          Washington DC
        </option>
        <option
          selected={certificateInfo.licenseState == 'West Virginia'}
          value="West Virginia"
        >
          West Virginia
        </option>
        <option
          selected={certificateInfo.licenseState == 'Wisconsin'}
          value="Wisconsin"
        >
          Wisconsin
        </option>
        <option
          selected={certificateInfo.licenseState == 'Wyoming'}
          value="Wyoming"
        >
          Wyoming
        </option>
      </select>
    );
  };

  return (
    <div key={course.code} style={{ marginTop: 25 }}>
      <h6 style={{ color: 'black', marginBottom: 0 }}>{course.programTitle}</h6>
      <p
        style={{
          color: 'black',
          fontStyle: 'italic',
          fontSize: 12,
          marginBottom: 0,
        }}
      >
        {course.title.split('::').join(',')}
      </p>

      <p style={{ color: 'black', marginBottom: 0 }}>
        Hours Completed: {course.hoursFulfilled}
      </p>
      <p style={{ color: 'black', marginBottom: 1 }}>
        Price: ${amountDue.toFixed(2)}
      </p>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        {!showCode ? (
          <p style={{ color: 'black' }}>
            {couponResponse?.error == false ? (
              <p style={{ color: 'green', marginBottom: 0 }}>Coupon Applied!</p>
            ) : (
              <>
                <a
                  style={{ cursor: 'pointer' }}
                  onClick={() => setShowCode(true)}
                >
                  Have a Coupon?
                </a>
              </>
            )}
          </p>
        ) : (
          <div>
            <label style={{ color: 'black', width: '100%', marginBottom: 0 }}>
              Coupon Code
              <input
                type="text"
                name="coupon"
                id="coupon"
                onChange={(e) => setCode(e.target.value)}
              />
            </label>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <a
                onClick={applyCode}
                style={{ marginRight: 15, cursor: 'pointer' }}
              >
                Apply
              </a>
              <a
                style={{ cursor: 'pointer' }}
                onClick={() => setShowCode(false)}
              >
                Cancel
              </a>
            </div>
            {couponResponse?.error == true && (
              <p style={{ color: 'red', marginBottom: 0 }}>Invalid Code</p>
            )}
          </div>
        )}
      </div>

      <button style={{ marginBottom: 50 }} onClick={surveyPay}>
        Pay for Certificate
      </button>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <h2 style={{ color: 'black', marginBottom: 5 }}>Licensure Info</h2>
        <form
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
          }}
        >
          {/* <label style={{ color: 'black' }}>
            Satisfaction
            <select>
              <option>Satisfied</option>
              <option>Not Satisfied</option>
              <option>Extremeley Not Satisfied - Josh Dumb</option>
            </select>
          </label>
          <label style={{ color: 'black' }}>
            Feedback
            <textarea
              name="message"
              id="message"
              rows="5"
              onChange={(e) => setMessage(e.target.value)}
            />
          </label> */}
          <label
            style={{ color: 'black', width: '49%', minWidth: 220, margin: 0 }}
          >
            Full Name
            <input
              type="text"
              name="name"
              id="name"
              width="40%"
              onChange={(e) =>
                setCertificateInfo({
                  ...certificateInfo,
                  fullName: e.target.value,
                })
              }
            />
          </label>
          <label
            style={{ color: 'black', width: '49%', minWidth: 220, margin: 0 }}
          >
            Email
            <input
              type="text"
              name="email"
              id="email"
              value={certificateInfo.email}
              onChange={(e) =>
                setCertificateInfo({
                  ...certificateInfo,
                  email: e.target.value,
                })
              }
            />
          </label>
          <label
            style={{ color: 'black', width: '49%', minWidth: 220, margin: 0 }}
          >
            License Type
            <input
              type="text"
              name="license"
              id="license"
              onChange={(e) =>
                setCertificateInfo({
                  ...certificateInfo,
                  licenseType: e.target.value,
                })
              }
            />
          </label>
          <label
            style={{ color: 'black', width: '49%', minWidth: 220, margin: 0 }}
          >
            License State
            <SelectState />
          </label>
          <label
            style={{ color: 'black', width: '49%', minWidth: 220, margin: 0 }}
          >
            License Expiration
            <input
              type="date"
              name="expiration"
              id="expiration"
              onChange={(e) =>
                setCertificateInfo({
                  ...certificateInfo,
                  licenseExpiration: e.target.value,
                })
              }
            />
          </label>
          <label
            style={{ color: 'black', width: '49%', minWidth: 220, margin: 0 }}
          >
            License Number
            <input
              type="text"
              name="license_number"
              id="license_number"
              onChange={(e) =>
                setCertificateInfo({
                  ...certificateInfo,
                  licenseNumber: e.target.value,
                })
              }
            />
          </label>
          <label
            style={{ color: 'black', width: '49%', minWidth: 220, margin: 0 }}
          >
            Mailing Address
            <input
              type="text"
              name="address"
              id="address"
              onChange={(e) =>
                setCertificateInfo({
                  ...certificateInfo,
                  address: e.target.value,
                })
              }
            />
          </label>
          <label
            style={{ color: 'black', width: '49%', minWidth: 220, margin: 0 }}
          >
            City
            <input
              type="text"
              name="city"
              id="city"
              onChange={(e) =>
                setCertificateInfo({ ...certificateInfo, city: e.target.value })
              }
            />
          </label>
          <label
            style={{ color: 'black', width: '49%', minWidth: 220, margin: 0 }}
          >
            State
            <input
              type="text"
              name="state"
              id="state"
              onChange={(e) =>
                setCertificateInfo({
                  ...certificateInfo,
                  state: e.target.value,
                })
              }
            />
          </label>
          <label
            style={{ color: 'black', width: '49%', minWidth: 220, margin: 0 }}
          >
            Zip Code
            <input
              type="text"
              name="zip"
              id="zip"
              onChange={(e) =>
                setCertificateInfo({ ...certificateInfo, zip: e.target.value })
              }
            />
          </label>
          <label
            style={{ color: 'black', width: '49%', minWidth: 220, margin: 0 }}
          >
            Phone Number
            <input
              type="text"
              name="phone"
              id="phone"
              onChange={(e) =>
                setCertificateInfo({
                  ...certificateInfo,
                  phone: e.target.value,
                })
              }
            />
          </label>
          <div
            style={{
              border: '2px solid lightgray',
              padding: 10,
              marginTop: 20,
              width: '100%',
            }}
          >
            <h2
              style={{
                color: 'black',
                marginTop: 10,
                marginBottom: 5,
                width: '100%',
              }}
            >
              Payment Info
            </h2>
            <p style={{ color: 'black', width: '100%' }}>
              Payment Amount: ${amountDue.toFixed(2)}
            </p>
            {_renderPayment()}
          </div>
        </form>
      </Modal>
    </div>
  );
};

const Certificate = ({ cert }) => {
  const [emailSent, setEmailSent] = useState(false);
  const [emailError, setEmailError] = useState(false);

  const sendToEmail = () => {
    try {
      axios.post(`${API_URL}/certificate/pdf`, {
        certificateId: cert.uuid,
        email: cert.email,
      });
      setEmailSent(true);
    } catch (err) {
      setEmailError(true);
    }
  };
  return (
    <div key={cert.uuid} style={{ marginBottom: 30 }}>
      <h6 style={{ color: 'black', marginBottom: 0 }}>{cert.title}</h6>
      <p style={{ color: 'black', marginBottom: 0 }}>
        Credit Hours: {cert.credit_hours}{' '}
        <a
          style={{ cursor: 'pointer', fontSize: 14 }}
          onClick={() =>
            (window.location.href = `/certificate?id=${cert.uuid}`)
          }
        >
          Preview
        </a>
      </p>
      {emailSent ? (
        <p style={{ color: 'green' }}>Email Sent!</p>
      ) : (
        <a style={{ cursor: 'pointer', marginRight: 10 }} onClick={sendToEmail}>
          Email Me a Copy
        </a>
      )}
      {emailError && (
        <p style={{ color: 'red' }}>
          Error sending email, please try again or contact us if the issue
          persists.
        </p>
      )}
    </div>
  );
};

export default MyAccount;
